import React from 'react'
import { uniqBy } from 'lodash'
import mapboxgl, { IControl } from 'mapbox-gl'
import ReactMapboxGl, { Layer } from 'react-mapbox-gl'
import DrawControl from 'react-mapbox-gl-draw'
import DrawStyles from './map/draw_theme'
import { Column } from './flex'
import { debug, getAmenityImage, POLYGON_TYPES, LINE_TYPES } from '../common'
import Selector from './map/selector'
import PointPopup from './map/point_popup'
import LinePopup from './map/line_popup'
import DrawTools from './map/draw_tools'
import { DrawMode } from '../types/draw_mode'
import Amenity from '../models/amenity'
import Floor from '../models/floor'
import Place from '../models/place'
import Feature from '../models/feature'
import Style from '../models/style'
import { POI_TYPE } from '../models/poi_type'
import ChangesBar from './map/changes_bar'
import ChangeContainer from '../models/change_container'
import PassiveSelect from './map/draw_modes/passive_selector'
import DrawText from './map/draw_modes/draw_text'
import { METADATA_POLYGON_EDITING } from '../constants'
import convex from '@turf/convex'
import explode from '@turf/explode'
import notifier from '../notifier'
import Measure from './map/draw_modes/measure'
import { marker as markerIcon } from '../icons'
import DrawLineString from './map/draw_modes/draw_line_string'
import DrawPoint from './map/draw_modes/draw_point'
import DrawPolygon from './map/draw_modes/draw_polygon'
import ItemSelect from './map/item_select'
import RouteBar from './map/route_bar'
import deepEqual from 'deep-equal'

const modes = {
  simple_select: PassiveSelect,
  direct_select: require('@mapbox/mapbox-gl-draw/src/modes/direct_select').default,
  draw_point: DrawPoint,
  draw_polygon: DrawPolygon,
  draw_line_string: DrawLineString,
  draw_text: DrawText
}

const CONTAINER_STYLE = { width: '100%', height: '100%' }

export type BoundsArray = [[number, number], [number, number]]

export type MapViewOptions = {
  zoom: number,
  coordinates: [number, number],
  pitch: number,
  bearing: number,
  bounds: BoundsArray
}

interface Props {
  style: Style
  mapboxToken: string
  proximiioToken: string
  latitude: number
  longitude: number
  zoom: number
  amenities: Amenity[]
  floor: Floor
  floors: Floor[]
  place: Place
  places: Place[]
  selected: Feature[]
  selectorFeatures: Feature[]
  changes: ChangeContainer[]
  onCenterChange: (center: [number, number]) => void
  onFloorSelect: (floor: Floor) => void
  onPlaceSelect: (place: Place) => void
  onMapSelection: (features: Array<Feature>) => void
  onBoxSelection: (feature: Feature) => void
  onReady: () => void
  onDrawModeChange: (mode: DrawMode) => void
  onFeatureCreate: (feature: Feature, synthetic: boolean) => void
  onFeatureUpdate: (feature: Feature, geometryOnly: boolean) => void
  onFeatureDelete: (feature: Feature) => void
  onFeatureQueryChange: (query: string) => void
  onRouteUpdate: (start?: Feature, finish?: Feature) => void
  loadingRoute: boolean
  route?: any
  onRouteCancel: () => void
  onSave: () => void
  onOptionsChange: (options: MapViewOptions) => void
  onRasterToggle: (value: boolean) => void
  onInspect: (features: Feature[]) => void
  inspect: boolean
}

interface State {
  center: [number, number]
  zoom: [number]
  loading: boolean
  style: any
  images: Array<any>
  isDragging: boolean
  mode: DrawMode
  emptyCoordinates?: mapboxgl.LngLat
  marker?: mapboxgl.Marker
  routeStart?: Feature
  routeFinish?: Feature
  mouseDown: boolean
  preSelection: Feature[]
  options: MapViewOptions
  showEmpty: boolean
}

const MapboxGL = ReactMapboxGl({
  accessToken: '',
  attributionControl: false
})

export default class MapView extends React.Component<Props, State> {
  map?: mapboxgl.Map
  dragHandle?: number
  ref = React.createRef<any>()
  drawRef = React.createRef<any>()
  measure = new Measure({}) as IControl

  constructor(props: Props) {
    super(props)

    this.state = {
      loading: true,
      style: Object.assign({}, props.style.json),
      center: [this.props.longitude, this.props.latitude],
      zoom: [this.props.zoom],
      images: [],
      isDragging: false,
      mode: 'simple_select',
      mouseDown: false,
      preSelection: [],
      options: {
        zoom: this.props.zoom,
        coordinates: [this.props.longitude, this.props.latitude],
        pitch: props.style.json.pitch || 0,
        bearing: props.style.json.pitch || 0,
        bounds: [[0, 0], [0, 0]]
      },
      showEmpty: false
    }

    this.transformRequest = this.transformRequest.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onZoomEnd = this.onZoomEnd.bind(this)
    this.onPitchEnd = this.onPitchEnd.bind(this)
    this.onRotateEnd = this.onRotateEnd.bind(this)
    this.onMoveEnd = this.onMoveEnd.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onDrawCreate = this.onDrawCreate.bind(this)
    this.onDrawUpdate = this.onDrawUpdate.bind(this)
    this.onDrawDelete = this.onDrawDelete.bind(this)
    this.onDrawModeChange = this.onDrawModeChange.bind(this)
    this.onDrawSelectionChange = this.onDrawSelectionChange.bind(this)
    this.renderPointPopup = this.renderPointPopup.bind(this)
    this.renderPolygonPopup = this.renderPolygonPopup.bind(this)
    this.renderEmptyPopup = this.renderEmptyPopup.bind(this)
    this.onLayerToggle = this.onLayerToggle.bind(this)
    this.addMarker = this.addMarker.bind(this)
    this.removeMarker = this.removeMarker.bind(this)
    this.onRouteStart = this.onRouteStart.bind(this)
    this.onRouteFinish = this.onRouteFinish.bind(this)
    this.onPointAdd = this.onPointAdd.bind(this)
    this.onSelectFeature = this.onSelectFeature.bind(this)
    this.setInitialOptions = this.setInitialOptions.bind(this)
  }

  componentDidMount() {
    this.processImages()
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.style, prevProps.style)) {
      if (prevProps.style?.pitch !== this.props.style.pitch) {
        this.map?.setPitch(this.props.style.pitch);
        const options = Object.assign({}, this.state.options);
        options.pitch = this.props.style.pitch;
      }
      if (prevProps.style?.bearing !== this.props.style.bearing) {
        this.map?.setBearing(this.props.style.bearing);
        const options = Object.assign({}, this.state.options);
        options.bearing = this.props.style.bearing;
      }
    }
  }

  transformRequest(url: string, resourceType: string) {
    if (url.match('proximi.fi')) {
      return {
        url,
        headers: { 'Authorization': `Bearer ${this.props.proximiioToken}` }
      }
    }
    return url
  }

  isDrawing() {
    return this.state.mode !== 'simple_select'
  }

  private onContextMenu = async (map: mapboxgl.Map, e: any) => {
    this.setState({
      emptyCoordinates: e.lngLat,
      showEmpty: true
    })
  }

  async onClick (map: mapboxgl.Map, e: any) {
    // ignore click processing when in drawing mode
    if (this.isDrawing()) {
      return
    }

    this.removeMarker();
    this.props.onMapSelection([]);
    const sources = ['main', 'synthetic', 'route', 'clusters'];
    const features = uniqBy(
      map.queryRenderedFeatures(e.point)
        .filter(feature => sources.includes(feature.source))
        .map(f => new Feature((f as any).toJSON())),
        feature => feature.properties.id || feature.id
    );

    // console.log('click features', features)
    const cluster = features.find(f => f.properties.cluster);
    if (cluster) {
      if (this.map) {
        const source = this.map.getSource('clusters') as any
        source.getClusterExpansionZoom(cluster.id, (error: any, zoom: number) => {
          if (this.map) {
            this.map.flyTo({ center: cluster.geometry.coordinates as any, zoom })
          }
        })
      }
      return
    }

    if (features.length > 0) {
      const currentTypes = ['Point', ...LINE_TYPES]
      if (this.props.style.metadata[METADATA_POLYGON_EDITING]) {
        currentTypes.push(...POLYGON_TYPES)
      }

      const available = features
        .filter(f => currentTypes.includes(f.geometry.type))
        .filter(f => f.geometry.type === 'Polygon' ? (f.properties.routable || f.properties.segment || f.properties.room) : true)

      // console.log('available', available);
      if (available.length > 0) {
        this.props.onMapSelection(available)
      }
      // if (available.length === 1) {
      // } else if (available.length > 1) {
        // console.log('should show preselection', features, 'at', e.lngLat);
        // this.setState({ preSelection: features, emptyCoordinates: e.lngLat }, () => {
        //  this.addMarker()
        // })
      // }
    } else {
      this.setState({ preSelection: [], emptyCoordinates: e.lngLat })
    }
  }

  addMarker() {
    if (!this.map || !this.state.emptyCoordinates) {
      return
    }

    const icon = <img alt="marker" className='toolbar-icon' src={markerIcon}/>
    const marker = new mapboxgl.Marker(icon as any)
      .setLngLat(this.state.emptyCoordinates as any)

    marker.addTo(this.map)
    this.setState({ marker })
  }

  removeMarker() {
    if (this.state.marker) {
      this.state.marker.remove()
    }
    this.setState({ emptyCoordinates: undefined })
  }

  async processImages() {
    const results = await Promise.all(this.props.amenities.map(amenity => getAmenityImage(amenity)))
    const images = results
      .filter((result: any) => result.success)
      .map((result: any) => [result.amenity.id, result.img, result.amenity.title])
    this.setState({ images })
  }

  async onMoveEnd(map: mapboxgl.Map, evt: React.SyntheticEvent<any>) {
    const { lng, lat } = map.getCenter();
    this.props.onCenterChange([lng, lat]);
    this.setState({
      options: {
        ...this.state.options,
        bounds: map.getBounds().toArray() as BoundsArray,
        coordinates: [lng, lat]
      }
    }, () => {
      this.props.onOptionsChange(this.state.options)
    })
  }

  onDrawCreate(props: any) {
    console.log('onDrawCreate', props);
    const feature = new Feature(props.features[0]);
    if (feature.isText) {
      feature.properties.title_i18n = { en: 'Edit me' };
      feature.properties.textColor = '#000000';
      feature.properties.textSize = 14;
      feature.properties.textFont = [
        "Klokantech Noto Sans Bold"
      ];
    }

    if (feature.isPoint && !feature.isText) {
      feature.properties.usecase = 'poi';
      feature.properties.type = POI_TYPE.POI;
      feature.properties.amenity = 'default';
      feature.properties.title = 'New POI';
    }

    if (feature.isLineString) {
      feature.properties.class = 'path';
      feature.properties.visibility = 'visible';
    }

    debug.log('map on feature create', feature)
    this.props.onFeatureCreate(feature, false)
  }

  onDrawUpdate(props: any) {
    this.setState({ isDragging: false })
    debug.log('on draw update', props, this.props.selected, 'props', props)
    const prev = this.props.selected.find(f => f.properties.id === props.features[0].properties.id)

    if (prev) {
      const feature = new Feature(prev.json)
      feature.geometry.coordinates = props.features[0].geometry.coordinates
      this.props.onFeatureUpdate(feature, false)
    }
  }

  async onDrawDelete(props: any) {
    const feature = new Feature(props.features[0])
    this.props.onMapSelection([])
    this.removeMarker()
    this.props.onFeatureDelete(feature)
  }

  onDragStart() {

  }

  onDragStop() {

  }

  async onMouseMove(e :any, evt: any) {
    // console.log('on mouse move', e, this.state.mode, 'point', e.point, 'latlng', e.lngLat, evt)
    const isDragging = this.state.mouseDown
    if (this.state.isDragging !== isDragging) {
      this.setState({ isDragging }, () => {
        if (isDragging) {
          this.onDragStart()
        } else {
          this.onDragStop()
        }
      })
    }
    
    if (this.props.inspect) {
      // const sources = ['main', 'synthetic', 'route', 'clusters']
      // console.log('queried', this.map?.queryRenderedFeatures(evt.point), 'at', evt.point)
      const features = uniqBy(
        this.map?.queryRenderedFeatures(evt.point)
          .map(f => new Feature((f as any).toJSON())),
        feature => feature.properties.id || feature.id
      )
      // console.log('inspecting', features)
      this.props.onInspect(features)
    }
  }

  onMouseDown(e: any) {
    if (!this.state.mouseDown) {
      this.setState({ mouseDown: true })
    }
  }

  onMouseUp(e: any) {
    if (this.state.mouseDown) {
      this.setState({ mouseDown: false, isDragging: false })
    }
    this.onDragStop()
  }

  async onZoomEnd(map: mapboxgl.Map) {
    this.setState({
      options: {...this.state.options, zoom: map.getZoom(), bounds: map.getBounds().toArray() as BoundsArray}
    }, () => {
      this.props.onOptionsChange(this.state.options)
    })
  }

  async onPitchEnd(map: mapboxgl.Map) {
    this.setState({
      options: {...this.state.options, pitch: map.getPitch(), bounds: map.getBounds().toArray() as BoundsArray,}
    }, () => {
      this.props.onOptionsChange(this.state.options)
    })
  }

  async onRotateEnd(map: mapboxgl.Map) {
    this.setState({
      options: {...this.state.options, bearing: map.getBearing(), bounds: map.getBounds().toArray() as BoundsArray}
    }, () => {
      this.props.onOptionsChange(this.state.options)
    })
  }

  async setInitialOptions(map: mapboxgl.Map) {
    this.setState({
      options: {
        ...this.state.options,
        coordinates: map.getCenter().toArray() as [number, number],
        zoom: map.getZoom(),
        bearing: this.state.style.bearing || map.getBearing(),
        pitch: this.state.style.pitch || map.getPitch(),
        bounds: map.getBounds().toArray() as BoundsArray
      }
    }, () => {
      this.map?.addControl(this.measure, 'bottom-right')
      this.map?.setBearing(this.props.style.bearing);
      this.map?.setPitch(this.props.style.pitch);
      this.props.onOptionsChange(this.state.options)
    })
  }

  onLayerToggle(layer: string, value: boolean) {
    if (this.map) {
      if (layer === 'paths') {
        const layer = this.map.getLayer('proximiio-paths') ? 'proximiio-paths' : 'paths';
        if (this.map.getLayer(layer)) {
          this.map.setLayoutProperty(layer, 'visibility', value ? 'visible' : 'none')
        } 
        return
      }

      if (layer === 'extrusions') {
        const style = this.map.getStyle()
        if (style && style.layers) {
          const layers = style.layers.filter(layer => layer.type === "fill-extrusion")
          layers.forEach(layer => (this.map as any).setLayoutProperty(layer.id, 'visibility', value ? 'visible' : 'none'))
        }
        return
      }

      if (layer === 'raster') {
        this.props.onRasterToggle(value)
      }

      if (layer === 'simple-tiles-layer') {
        this.map.setLayoutProperty(layer, 'visibility', value ? 'visible' : 'none')
      }
    }
  }

  async onRouteStart(start: Feature) {
    this.removeMarker()
    this.setState({ routeStart: start }, () => {
      this.props.onRouteUpdate(this.state.routeStart, this.state.routeFinish)
      this.props.onMapSelection([ start ])
    })
  }

  async onRouteFinish(finish: Feature) {
    this.removeMarker()
    this.setState({ routeFinish: finish }, () => {
      this.props.onMapSelection([ finish ])
      this.props.onRouteUpdate(this.state.routeStart, this.state.routeFinish)
    })
  }

  onDrawSelectionChange(event: any) {
    if (event.bbox && event.features && event.features.length > 0) {
      const exploded = [] as Feature[]
      event.features
        .filter((f: any) => f.source === 'main')
        .forEach((f: any) => {
          const existing = this.props.style.sources.main.data.features
            .find((feat: Feature) => feat.properties.id === f.properties.id)
          if (existing) {
            if (existing.geometry.type === 'Point') {
              exploded.push(existing)
            } else {
              const collection = explode(f)
              const points = collection.features.map((f: any) => new Feature(f))
              exploded.push(...points)
            }
          }
        })

      if (exploded.length > 0) {
        const collection = { type: 'FeatureCollection', features: exploded }
        const polygon = new Feature(convex(collection as any))
        this.props.onBoxSelection(polygon)
      }
    }
  }

  async onDrawModeChange(mode: DrawMode) {
    if (mode === 'trash') {
      this.drawRef.current?.draw.trash()
      return
    }

    const measure = this.measure as any
    if (measure.isMeasuring) {
      measure.measuringOff()
    }

    this.setState({ mode }, () => {
      const customModes = ['settings', 'inspect', 'measure', 'visibility']
      if (!customModes.includes(mode)) {
        this.drawRef.current?.draw.changeMode(mode)
      } else if (mode === 'measure') {
        (this.measure as any).measuringOn()
      }
      this.props.onDrawModeChange(mode)
    })
  }

  onPointAdd(feature: Feature) {
    this.removeMarker()
    this.props.onFeatureCreate(feature, false)
    // this.setState({ emptyCoordinates: undefined })
  }

  async onSelectFeature(feature: Feature) {
    if (this.map) {
      this.props.onMapSelection([feature]);
      const floor = this.props.floors.find(f => f.id === feature.properties.floor_id);
      if (!floor) {
        notifier.failure('Floor assigned to feature was not found, skipping level switch');
      } else {
        this.props.onFloorSelect(floor);
      }

      this.map.flyTo({ center: feature.geometry.coordinates as any, zoom: 19 });
    }
  }

  renderEmptyPopup() {
    if (!this.state.emptyCoordinates || this.state.isDragging || !this.state.showEmpty) {
      return
    }

    return <ItemSelect
      coordinates={this.state.emptyCoordinates as mapboxgl.LngLat}
      floor={this.props.floor}
      selected={this.state.preSelection}
      enablePolygons={this.props.style.metadata[METADATA_POLYGON_EDITING]}
      onPointAdd={this.onPointAdd}
      onRouteStart={this.onRouteStart}
      onRouteFinish={this.onRouteFinish}
      onSelect={async feature => {
        this.setState({ preSelection: [], emptyCoordinates: undefined }, () => {
          this.removeMarker();
          this.props.onMapSelection([feature]);
        })
      }}
      onClose={() => {
        this.props.onMapSelection([]);
        this.removeMarker();
      }}
    />
  }

  renderPointPopup() {
    const point = this.props.selected.filter(f => f && f.geometry).find(feature => feature.geometry.type === 'Point')
    // console.log('point', point)
    if (!point || this.state.isDragging) {
      return undefined
    }

    return <PointPopup
      point={point}
      onDelete={async (feature: Feature) => {
        this.props.onMapSelection([])
        this.removeMarker()
        this.props.onFeatureDelete(feature)
      }}
    />
  }

  renderLinePopup() {
    const line = this.props.selected.filter(f => f && f.geometry).find(feature => feature.isLineString)

    if (!line || this.state.isDragging) {
      return undefined
    }

    return <LinePopup
      line={line}
      onDelete={async (feature: Feature) => {
        this.props.onMapSelection([])
        this.removeMarker()
        this.props.onFeatureDelete(feature)
      }}
    />
  }

  renderPolygonPopup() {
    const polygon = this.props.selected.filter(f => f && f.geometry).find(feature => feature.isPolygon)

    if (!polygon || this.state.isDragging) {
      return undefined
    }

    return <LinePopup
      line={polygon}
      onDelete={async (feature: Feature) => {
        this.props.onMapSelection([])
        this.removeMarker()
        this.props.onFeatureDelete(feature)
      }}
    />
  }

  renderChangesBar() {
    if (this.props.changes.length === 0) {
      return
    }

    return <ChangesBar
      changes={this.props.changes}
      onSave={this.props.onSave}
    />
  }

  render() {
    return <Column flex={4}>
      <div style={{ width: '100%', height: '100%'}}>
        <MapboxGL
          ref={this.ref}
          containerStyle={CONTAINER_STYLE}
          style={this.state.style}
          onStyleLoad={(map) => {
            this.map = map;
            this.setInitialOptions(map);
            this.props.onReady();
            if (this.props.style.hasCustomTiles()) {
              this.map.moveLayer('simple-tiles-layer', 'proximiio-floors')
            }
          }}
          zoom={this.state.zoom}
          center={this.state.center}
          // onContextMenu={this.onContextMenu}
          onClick={this.onClick}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseMove={this.onMouseMove}
          onMoveEnd={this.onMoveEnd}
          onPitchEnd={this.onPitchEnd}
          onRotateEnd={this.onRotateEnd}
          onZoomEnd={this.onZoomEnd}>
          { this.state.images.length > 0 && <Layer images={this.state.images}/> }
          { this.renderChangesBar() }

          <DrawControl
            ref={this.drawRef}
            displayControlsDefault={false}
            styles={DrawStyles}
            modes={modes}
            default_mode={this.state.mode}
            onDrawSelectionChange={this.onDrawSelectionChange}
            onDrawCreate={this.onDrawCreate}
            onDrawUpdate={this.onDrawUpdate}
            onDrawDelete={this.onDrawDelete}
            onDrawModeChange={(evt) => {
              this.setState({ mode: evt.mode })
            }}
          />

          { this.renderPointPopup() }
          { this.renderLinePopup() }
          { this.renderPolygonPopup() }
          { this.renderEmptyPopup() }

          <DrawTools
            mode={this.state.mode}
            onSelect={this.onDrawModeChange}
          />
{/*
          <ManageBar
            style={this.props.style}
          /> */}
{/*
          <LayerToggler
            style={this.props.style}
            onLayerToggle={this.onLayerToggle}
          /> */}

          { this.props.route && this.props.route.features.length > 0 && <RouteBar
            route={this.props.route}
            isLoading={this.props.loadingRoute}
            onCancel={this.props.onRouteCancel}
          /> }

          
          { this.props.style.metadata['proximiio:raster:tileurl'] &&
            <Layer 
              type="raster" 
              id="simple-tiles-layer" 
              sourceId="simple-tiles-source"
            />
          }
        </MapboxGL>

        <Selector
          features={this.props.selectorFeatures}
          floor={this.props.floor}
          floors={this.props.floors}
          place={this.props.place}
          places={this.props.places}
          onFeatureQueryChange={this.props.onFeatureQueryChange}
          onFeatureSelect={this.onSelectFeature}
          onFloorSelect={this.props.onFloorSelect}
          onPlaceSelect={this.props.onPlaceSelect}
        />
      </div>
    </Column>
  }
}
