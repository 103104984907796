import React, { useState } from 'react'
import { Alert, Popover, Button, FileInput, InputGroup } from '@blueprintjs/core'
import { Column, Row} from '../../flex'
import { convertToBase64 } from '../../../common'
import Amenity from '../../../models/amenity'

interface Props {
  amenity: Amenity
  isOpen: boolean
  onDelete: (amenity: Amenity) => void
  onSave: (amenity: Amenity) => void
  onClose: () => void
}

export default function AmenityPopover({
    children, amenity, isOpen, onSave, onClose, onDelete
  }: React.PropsWithChildren<Props>): React.ReactElement {
  const [ title, setTitle ] = useState(amenity.exists ? amenity.title : '')
  const [ icon, setIcon ] = useState(amenity.exists ? amenity.icon : '')
  const [ showAlert, setShowAlert ] = useState(false)

  const save = () => {
    amenity.title = title
    amenity.icon = icon
    onSave(amenity)
  }

  return <Popover isOpen={isOpen}>
    { children }
    <div style={{padding: 10}}>
      <Row flex={1}>
        <InputGroup
          fill
          style={{ width: '100%' }}
          placeholder="Enter amenity title"
          value={title}
          onChange={(e: any) => setTitle(e.currentTarget.value)}
        />
      </Row>
      <Row flex={1}>
        <Column flex={2}>
          <FileInput
            text={icon.length > 0 ? 'Image Selected' : 'Choose image...'}
            inputProps={{
              accept: ".png",
              multiple: false
            }}
            hasSelection={icon.length > 0}
            onInputChange={async value => {
              if (value.currentTarget.files) {
                const file = value.currentTarget.files[0]
                const icon = await convertToBase64(file)
                setIcon(icon)
              }
            }}
          />
        </Column>
      </Row>
      <Row flex={1}>
        <p className="hint">Use 128px x 128px .png image</p>
      </Row>
      <Row flex={1} style={{ justifyContent: 'end' }}>
        <Button icon="tick" intent="success" onClick={() => {
          if (amenity.exists) {
            amenity.title = title
            amenity.icon = icon
          }
          save()
        } }/>

        { amenity.exists && <Button
          icon="trash"
          intent="danger"
          minimal
          onClick={() => setShowAlert(true)} /> }

        { amenity.exists && <Alert
          isOpen={showAlert}
          cancelButtonText="Cancel"
          icon="trash"
          confirmButtonText="Remove Amenity"
          onCancel={() => setShowAlert(false)}
          onConfirm={() => onDelete(amenity)}
          intent="danger"
        > Amenity removal confirmation
        </Alert> }

        <Button icon="cross" minimal  onClick={onClose} />
      </Row>
    </div>
  </Popover>
}
