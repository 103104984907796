import React from 'react'
import { Button, FormGroup, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { Column } from '../flex'
import AmenityIcon from '../amenity_icon'

import Amenity from '../../models/amenity'
import AmenityCategory from '../../models/amenity_category'
import Feature from '../../models/feature'
import deepEqual from 'deep-equal'
import { changes, debug } from '../../common'

const AmenityCategorySelect = Select.ofType<AmenityCategory>()
const AmenitySelect = Select.ofType<Amenity>()

interface Props {
  amenities: Array<Amenity>
  amenityCategories: Array<AmenityCategory>
  feature: Feature
  onChange: (feature: Feature) => void
}

interface State {
  amenities: Array<Amenity>
  categories: AmenityCategory[]
  feature: Feature
  category: AmenityCategory
}

export default class AmenityPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      amenities: this.props.amenities,
      category: new AmenityCategory({}),
      categories: this.sortCategories(this.props.amenityCategories),
      feature: Object.assign({}, this.props.feature)
    }
    this.state = {...this.state, ...this.newState(props)}
    this.amenityRenderer = this.amenityRenderer.bind(this)
    this.onAmenitySelect =  this.onAmenitySelect.bind(this)
    this.onAmenityCategorySelect = this.onAmenityCategorySelect.bind(this)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const propsChanges = changes(this.props, prevProps)
    const stateChanges = changes(this.state, prevState)
    const state = this.newState(this.props)
    if (propsChanges.length === 0 && stateChanges.length === 2 && stateChanges[1] === 'category') {
      // console.log('should not further update manual category change')
    } else {
      if (!deepEqual(this.state, state)) {
        this.setState(state)
      }
    }
  }

  newState(props: Props) {
    const state = Object.assign({}, this.state) as State
    state.feature = Object.assign({}, props.feature)
    if (state.feature.properties.amenity) {
      const amenity = props.amenities.find(a => a.id === props.feature.properties.amenity)
      if (amenity) {
        const category = props.amenityCategories.find(c => c.id === amenity.category)
        if (category) {
          state.amenities = props.amenities.filter(a => a.category === category.id)
        }
        state.category = category ? category : new AmenityCategory({})
      } else {
        state.category = new AmenityCategory({})
      }
    }
    return state
  }

  sortCategories(categories: AmenityCategory[]) {
    return categories.sort((a, b) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    })
  }

  getIcon({ icon } : Amenity, width: number = 36) {
    return <img
      alt="icon"
      style={{ width }}
      src={icon}
    />
  }

  amenityCategoryRenderer(amenityCategory: AmenityCategory, options: any) {
    return <MenuItem
      key={amenityCategory.id}
      text={amenityCategory.title}
      onClick={options.handleClick}
    />
  }

  onAmenityCategorySelect(category: AmenityCategory) {
    this.setState({
      amenities: this.props.amenities.filter(a => a.category === category.id),
      category
    })
  }

  amenityRenderer(amenity: Amenity, options: any) {
    return <MenuItem
      key={amenity.id}
      icon={<AmenityIcon amenity={amenity} width={36}/>}
      text={<span style={{ lineHeight: '36px' }}>{ amenity.title }</span>}
      onClick={options.handleClick}
    />
  }

  onAmenitySelect(amenity: Amenity) {
    debug.log('onAmenitySelect', amenity, this.state.feature)
    const feature = new Feature(new Feature(this.state.feature).json)
    feature.properties.amenity = amenity.id
    this.setState({ feature })
    this.props.onChange(feature)
  }

  render() {
    const amenity = this.props.amenities.find(a => a.id === this.state.feature.properties.amenity)
    return <React.Fragment>
      <Column flex={1} className="edit-block">
        <FormGroup label="Category" inline={true}>
          <AmenityCategorySelect
            filterable={false}
            items={this.state.categories}
            itemRenderer={this.amenityCategoryRenderer}
            onItemSelect={this.onAmenityCategorySelect}>
            <Button
              minimal
              text={this.state.category && this.state.category.exists ? this.state.category.title : 'Select Category'}
              rightIcon="double-caret-vertical" />
          </AmenityCategorySelect>
        </FormGroup>
      </Column>
      <Column flex={1} className="edit-block">
        <FormGroup label="Amenity" inline={true}>
          <AmenitySelect
            disabled={!this.state.category.exists}
            items={this.state.amenities}
            itemRenderer={this.amenityRenderer}
            onItemSelect={this.onAmenitySelect}>
            <Button
              disabled={!this.state.category.exists}
              minimal
              icon={amenity ? this.getIcon(amenity, 20) : null}
              text={amenity && amenity.exists ? amenity.title : 'Select Amenity'}
              rightIcon="double-caret-vertical" />
          </AmenitySelect>
        </FormGroup>
      </Column>
    </React.Fragment>
  }
}