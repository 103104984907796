export default {
  'proximiio:languages': {
    'en': {
      title: 'English',
      level: {
        zero: 'levels',
        one: 'level',
        two: 'levels',
        few: 'levels',
        many: 'levels',
        other: 'levels'
      },
      meter: {
        zero: 'meters',
        one: 'meter',
        two: 'meters',
        few: 'meters',
        many: 'meters',
        other: 'meters'
      },
      turn_before: 'In %d %s %s.',
      turn_prepare: 'Prepare to %s in %d %s.',
      turn_now: '%s.',
      continue: 'Continue %d %s.',
      levelchange: 'Use %s to get to %d %s.',
      turn_left_hard: 'turn hard left',
      turn_left: 'turn left',
      turn_left_slight: 'turn slight left',
      turn_straight: 'continue straight',
      turn_right_slight: 'turn slight right',
      turn_right: 'turn right',
      turn_right_hard: 'turn right',
      turn_around: 'turn around',
      destination_nearing: 'In %d %s you will arrive at your destination.',
      destination_reached: 'You have reached your destination',
      canceled: 'Navigation was canceled.',
      calculating: 'Calculating route...',
      recalculating: 'Recalculating route...',
      path_not_found: 'Unable to find path.',
      level_change_down: 'go %d %s up to %s %d.',
      level_change_up: 'go %d %s up to %s %d.',
      passing_poi: 'Passing %s on your %s',
      left: 'left',
      right: 'right'
    },
    'fi': {
      title: 'English',
      level: {
        zero: 'levels',
        one: 'level',
        two: 'levels',
        few: 'levels',
        many: 'levels',
        other: 'levels'
      },
      meter: {
        zero: 'meters',
        one: 'meter',
        two: 'meters',
        few: 'meters',
        many: 'meters',
        other: 'meters'
      },
      turn_before: 'In %d %s %s.',
      turn_prepare: 'Prepare to %s in %d %s.',
      turn_now: '%s.',
      continue: 'Continue %d %s.',
      levelchange: 'Use %s to get to %d %s.',
      turn_left_hard: 'turn hard left',
      turn_left: 'turn left',
      turn_left_slight: 'turn slight left',
      turn_straight: 'continue straight',
      turn_right_slight: 'turn slight right',
      turn_right: 'turn right',
      turn_right_hard: 'turn right',
      turn_around: 'turn around',
      destination_nearing: 'In %d %s you will arrive at your destination.',
      destination_reached: 'You have reached your destination',
      canceled: 'Navigation was canceled.',
      calculating: 'Calculating route...',
      recalculating: 'Recalculating route...',
      path_not_found: 'Unable to find path.',
      level_change_down: 'go %d %s up to %s %d.',
      level_change_up: 'go %d %s up to %s %d.',
      passing_poi: 'Passing %s on your %s',
      left: 'left',
      right: 'right'
    }
  },
  'en': {
    title: 'English',
    level: {
      zero: 'levels',
      one: 'level',
      two: 'levels',
      few: 'levels',
      many: 'levels',
      other: 'levels'
    },
    meter: {
      zero: 'meters',
      one: 'meter',
      two: 'meters',
      few: 'meters',
      many: 'meters',
      other: 'meters'
    },
    turn_before: 'In %d %s %s.',
    turn_prepare: 'Prepare to %s in %d %s.',
    turn_now: '%s.',
    continue: 'Continue %d %s.',
    levelchange: 'Use %s to get to %d %s.',
    turn_left_hard: 'turn hard left',
    turn_left: 'turn left',
    turn_left_slight: 'turn slight left',
    turn_straight: 'continue straight',
    turn_right_slight: 'turn slight right',
    turn_right: 'turn right',
    turn_right_hard: 'turn right',
    turn_around: 'turn around',
    destination_nearing: 'In %d %s you will arrive at your destination.',
    destination_reached: 'You have reached your destination',
    canceled: 'Navigation was canceled.',
    calculating: 'Calculating route...',
    recalculating: 'Recalculating route...',
    path_not_found: 'Unable to find path.',
    level_change_down: 'go %d %s up to %s %d.',
    level_change_up: 'go %d %s up to %s %d.',
    passing_poi: 'Passing %s on your %s',
    left: 'left',
    right: 'right'
  }
}