import DataSource from './data_source'
import Feature, { FeatureCollection } from '../models/feature'
import ChangeContainer from '../models/change_container'
import { axios, debug } from '../common'
import deepEqual from 'deep-equal'

export default class GeoJSONSource extends DataSource {
  changes: ChangeContainer[] = []
  dirty: Feature[] = []
  deleted: Feature[] = []
  language: string = 'en'

  constructor(features: FeatureCollection) {
    super('main')
  }

  async fetch() {
    const response = await axios.get('/v5/geo/features?size=1500')
    this.data = new FeatureCollection(response.data)
    this.mapLanguage()
  }

  create(feature: Feature) {
    debug.log('[GEOJSON] creating feature', feature)
    const f = new Feature(feature)
    this.data.features.push(f)
    this.changes.push(new ChangeContainer('create', f))
    this.dirty.push(f)
    this.notify('feature-created')
  }

  update(feature: Feature) {
    debug.log('[GEOJSON] updating feature', feature)
    this.mapFeatureLanguage(feature, this.language)

    let idx = this.data.features.findIndex(f => f.id === feature.id || f.properties.id === feature.properties.id)
    let f = null
    if (idx >= 0) {
      if (!deepEqual(this.data.features[idx], feature, { strict: true })) {
        debug.log('features are not equal')
        f = this.data.features[idx]
        this.data.features.splice(idx, 1, feature)
        this.changes.push(new ChangeContainer('update', feature, f))
        debug.log('feature change generated')
      } else {
        debug.log('updated feature is equal to previous version', f, feature)
      }
    } else {
      debug.log('existing feature not found', feature)
    }

    const dirtyIdx = this.dirty.findIndex(f => f.id === feature.id || f.properties.id === feature.properties.id)
    if (f) {
      if (dirtyIdx >= 0) {
        this.dirty.splice(dirtyIdx, 1, feature)
      } else {
        this.dirty.push(feature)
      }
    }

    debug.log('feature updated', feature,f)
    this.notify('feature-updated')
  }

  delete(feature: Feature) {
    debug.log('[GEOJSON] deleting feature', feature)
    const idx = this.data.features.findIndex(f => f.properties.id === feature.properties.id)
    if (idx >= 0) {
      this.deleted.push(this.data.features[idx])
      this.data.features.splice(idx, 1)
      this.changes.push(new ChangeContainer('delete', new Feature(feature)))
    }

    const dirtyIdx = this.dirty.findIndex(f => f.properties.id === feature.properties.id)
    if (dirtyIdx >= 0 ) {
      this.dirty.splice(dirtyIdx, 1)
    }

    this.notify('feature-deleted')
  }

  resetDirty() {
    this.dirty = []
    this.changes = []
    this.deleted = []
    this.notify('dirty-reset')
  }

  mapLanguage() {
    const features = this.data.features.filter(f => typeof f.properties.title_i18n === 'object')
    features.forEach(feature => this.mapFeatureLanguage(feature, this.language))
  }

  mapFeatureLanguage(feature: Feature, language: string) {
    if (typeof feature.properties.title_i18n === 'string') {
      feature.properties.title_i18n = JSON.parse(feature.properties.title_i18n)
    }

    if (typeof feature.properties.title_i18n === 'object') {
      feature.properties.title = feature.properties.title_i18n[language]
    }
  }

  query(query: string, level = 0) {
    return this.data.features
      .filter(f => f.properties.title && f.properties.title.toLowerCase().match(query.toLowerCase()))
  }

  get(id: string) {
    return this.data.features.find(f => f.id === id) as Feature
  }

  getInternal(id: string) {
    return this.data.features.find(f => f.properties.id === id) as Feature
  }

  get collection() {
    return {
      type: 'FeatureCollection',
      features: this.data.features.map(f => f.json)
    } as FeatureCollection
  }
}