import Amenity from './models/amenity'
import AmenityCategory from './models/amenity_category'
import Place from './models/place'
import Floor from './models/floor'
import Style from './models/style'
import Feature from './models/feature'

import { axios } from './common'

const LIMIT = 5000

export const getAmenities = async () => {
  const amenitiesRes = await axios.get(`/v4/geo/amenities?size=${LIMIT}&isolated=false`)
  return amenitiesRes.data.map((item: any) => new Amenity(item))
}

export const getAmenityCategories = async () => {
  const categoriesRes = await axios.get(`/v5/geo/amenity_categories?size=${LIMIT}`)
  return categoriesRes.data.map((item: any) => new AmenityCategory(item))
}

export const getStyle = async (id?: string) => {
  let url = '/v5/geo/style'
  if (id) {
    url += `s/${id}`
  }
  const res = await axios.get(url)
  return new Style(res.data)
}

export const getStyles = async () => {
  let url = '/v5/geo/styles'
  const res = await axios.get(url)
  return res.data.map((item: any) => new Style(item))
}

export const getPlaces = async (skip: number = 0) => {
  const res = await axios.get(`/core/places?skip=${skip}&limit=${LIMIT}`)
  return res.data.map((item: any) => new Place(item))
}

export const getFloors = async (skip: number = 0, place_id?: string) => {
  const params = [ `skip=${skip}&limit=${LIMIT}` ]
  if (place_id) {
    params.push(`'place_id=${place_id}`)
  }
  const res = await axios.get(`/core/floors?${params.join('&')}`)
  const filter =  place_id ? (item: any) => item.place_id === place_id : (i: any) => i
  return res.data.filter(filter).map((item: any) => new Floor(item)).reverse() as Floor[]
}

export const getPackage = async () => {
  const result: any = {}
  const promises = [
    getPlaces().then(places => result.places = places),
    getFloors().then(floors => result.floors = floors),
    getStyle().then(style => result.style = style),
    getStyles().then(styles => result.styles = styles)
  ]
  await Promise.all(promises)
  return result
}

export const createAmenity = async (amenity: Amenity) => {
  const res = await axios.post('/v5/geo/amenities', amenity)
  return new Amenity(res.data)
}

export const createAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.post('/v5/geo/amenity_categories', category)
  return new AmenityCategory(res.data)
}

export const updateAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.put(`/v5/geo/amenity_categories/${category.id}`, category)
  return new AmenityCategory(res.data)
}

export const deleteAmenityCategory = async (category: AmenityCategory) => {
  const res = await axios.delete(`/v5/geo/amenity_categories/${category.id}`)
  return new AmenityCategory(res.data)
}

export const updateAmenity = async (amenity: Amenity) => {
  const res = await axios.post(`/v5/geo/amenities`, amenity)
  return new Amenity(res.data)
}

export const deleteAmenity = async (amenity: Amenity) => {
  const res = await axios.delete(`/v5/geo/amenities/${amenity.id}`)
  return new Amenity(res.data)
}

export const saveFeatures = async (features: Feature[]) => {
  const res = await axios.post('/v5/geo/features', {
    type: 'FeatureCollection',
    features: features.map(feature => feature.json)
  })
  return new Amenity(res.data)
}

export const deleteFeatures = async (_features: Feature[]) => {
  const features = _features.map(f => f.json)
  const res = await axios.post('/v5/geo/features/delete', {
    type: 'FeatureCollection',
    features
  })
  return res.data
}

export default {
  getPackage,
  getStyle,
  getStyles,
  getPlaces,
  getFloors,
  getAmenities,
  getAmenityCategories,
  saveFeatures,
  deleteFeatures,
  createAmenity,
  updateAmenity,
  deleteAmenity,
  createAmenityCategory,
  updateAmenityCategory,
  deleteAmenityCategory
}
