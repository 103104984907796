import React from 'react'
import logo from '../assets/proximiio.png'
import { Button, Classes, Dialog, FormGroup, InputGroup, ControlGroup, Spinner } from '@blueprintjs/core'
import { axios, hasStrings } from '../common'
import { User } from '../types/user'
import { version } from '../../package.json'

interface LoginProps {
  onLogin: (user: User) => void
}

interface LoginState {
  email: string,
  password: string,
  error: boolean,
  loggingIn: boolean
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: false,
      loggingIn: false
    }
    this.login = this.login.bind(this)
  }

  async login() {
    this.setState({ loggingIn: true }, async () => {
      const email = this.state.email
      const password = this.state.password
      if (hasStrings([email, password])) {
        try {
          const response = await axios.post('/core_auth/login', { email, password })
          const user = response.data as User
          this.props.onLogin(user)
        } catch (e) {
          this.setState({ error: true, loggingIn: false })
        }
      }
    })
  }

  update(event: any, field: string) {
    const state : any = {}
    state[field] = event.target.value
    this.setState(state)
  }

  renderError() {
    if (!this.state.error) {
      return null
    }

    return (<div className="row login-error">
      Invalid credentials
    </div>)
  }

  render() {
    return <div className="login center-column">
      <div className="center-row">
        <Dialog isOpen={true} style={{ width: 340 }}>
          <div className={Classes.DIALOG_HEADER}>
            <strong>Map Editor Login</strong>
          </div>
          <div className={Classes.DIALOG_BODY}>
            <div className="login-content">
              <img alt="logo" className="logo-top" src={logo}/>
              <div className="column">
                <ControlGroup fill style={{width: '100%'}}>
                  <FormGroup
                    label="Email">
                    <InputGroup
                      placeholder="Email"
                      className="text-input-plain"
                      value={this.state.email}
                      onChange={(event: any) => this.update(event, 'email')}
                    />
                  </FormGroup>
                </ControlGroup>

                <ControlGroup fill style={{width: '100%'}}>
                  <FormGroup
                    label="Password">
                    <InputGroup
                      placeholder="Password"
                      className="text-input-plain"
                      value={this.state.password}
                      onChange={(event: any) => this.update(event, 'password')}
                      type="password"
                    />
                  </FormGroup>
                </ControlGroup>

                { this.renderError() }
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <div className="row" style={{
                justifyContent: 'flex-start',
                flex: 1,
                lineHeight: 2}
              }>{version}</div>
              <div className="row" style={{justifyContent: 'flex-end'}}>
                { !this.state.loggingIn && <Button onClick={() => { this.login() }} text="LOGIN" intent="primary" /> }
                { this.state.loggingIn && <Spinner size={32} intent="primary" /> }
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  }
}