import React from 'react'
import { Spinner, Button, Tooltip, PopoverPosition } from '@blueprintjs/core'
import Toolbar from './toolbar'
import { Row } from '../flex'

interface Props {
  route: any
  isLoading: boolean
  onCancel: () => void
}

export default class RouteBar extends React.Component<Props> {
  renderLoading() {
    if (!this.props.isLoading) {
      return
    }

    return <Spinner intent="warning" size={24}/>
  }

  renderContent() {
    if (this.props.isLoading) {
      return
    }

    return <div className="route-bar-content">
      {/* <strong>{ this.props.route.distance.toFixed(1) }</strong> meters */}
      &nbsp;|&nbsp;&nbsp;
      {/* <strong>{ secondsToHuman(this.props.route.distanceTime) }</strong> */}
    </div>
  }

  render() {
    return <Toolbar className="route-bar">
      <div id="route-bar">
        <Row flex={1}>
          <Tooltip position={PopoverPosition.TOP_RIGHT} content="Cancel navigation">
            <Button onClick={this.props.onCancel} intent="danger" icon={!this.props.isLoading && "cross"}/>
          </Tooltip>

          { this.renderLoading() }
          { this.renderContent() }
        </Row>
      </div>
    </Toolbar>
  }
}