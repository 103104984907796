import React from 'react'
import { Spinner } from '@blueprintjs/core'

const Loading: React.FunctionComponent = (props: any) => {
  return <div className="loading">
    <div className="loading-container">
      <Spinner size={50} />
    </div>
  </div>
}

export default Loading
