import React from 'react'
import { FormGroup, MenuItem, Collapse, Button } from '@blueprintjs/core'
import { TimePicker } from '@blueprintjs/datetime'
import { Column, Row } from '../flex'
import Feature from '../../models/feature'
import deepEqual from 'deep-equal'

interface Props {
  feature: Feature
  onChange: (feature: Feature) => void
}

interface State {
  isOpen: boolean,
  monStart: string | null,
  monEnd: string | null,
  tueStart: string | null,
  tueEnd: string | null,
  wedStart: string | null,
  wedEnd: string | null,
  thuStart: string | null,
  thuEnd: string | null,
  friStart: string | null,
  friEnd: string | null,
  satStart: string | null,
  satEnd: string | null,
  sunStart: string | null,
  sunEnd: string | null
}

export default class WorkingHours extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.convertToState(props);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (!deepEqual(prevProps.feature, this.props.feature, { strict: true })) {
      this.setState(this.convertToState(this.props))
    }
  }

  private convertToState = (props: Props) => {
    const workingHours = this.props.feature.properties.workingHours || [
      [], // sun
      [], // mon
      [], // tue
      [], // wed
      [], // thu
      [], // fri
      []  // sat
    ];
    
    return {
      isOpen: this.state ? this.state.isOpen : false,
      monStart: workingHours[1].length === 2 ? workingHours[1][0] : null,
      monEnd: workingHours[1].length === 2 ? workingHours[1][1] : null,
      tueStart: workingHours[2].length === 2 ? workingHours[2][0] : null,
      tueEnd: workingHours[2].length === 2 ? workingHours[2][1] : null,
      wedStart: workingHours[3].length === 2 ? workingHours[3][0] : null,
      wedEnd: workingHours[3].length === 2 ? workingHours[3][1] : null,
      thuStart: workingHours[4].length === 2 ? workingHours[4][0] : null,
      thuEnd: workingHours[4].length === 2 ? workingHours[4][1] : null,
      friStart: workingHours[5].length === 2 ? workingHours[5][0] : null,
      friEnd: workingHours[5].length === 2 ? workingHours[5][1] : null,
      satStart: workingHours[6].length === 2 ? workingHours[6][0] : null,
      satEnd: workingHours[6].length === 2 ? workingHours[6][1] : null,
      sunStart: workingHours[0].length === 2 ? workingHours[0][0] : null,
      sunEnd: workingHours[0].length === 2 ? workingHours[0][1] : null,
    }
  }

  onChange(day: number, isStart: boolean, date: Date) {
    const workingHours = [
      [ this.state.sunStart, this.state.sunEnd ],
      [ this.state.monStart, this.state.monEnd ],
      [ this.state.tueStart, this.state.tueEnd ],
      [ this.state.wedStart, this.state.wedEnd ],
      [ this.state.thuStart, this.state.thuEnd ],
      [ this.state.friStart, this.state.friEnd ],
      [ this.state.satStart, this.state.satEnd ],
    ];

    const hourStr = date.getHours().toString().padStart(2, '0');
    const minuteStr = date.getMinutes().toString().padStart(2, '0');
    const time = `${hourStr}:${minuteStr}`;
    workingHours[day][isStart ? 0 : 1] = time;

    workingHours.forEach((times, index) => {
      if (times[0] === null && times[1] === null) {
        workingHours[index] = []
      } 
    })

    const feature = new Feature(this.props.feature.json);
    feature.properties.workingHours = workingHours;
    this.props.onChange(feature);
  }

  private getDay = (day: number): Date[] => {
    let values = [] as (string | null)[];

    if (day === 0) {
      values = [ this.state.sunStart, this.state.sunEnd ]
    } else if (day === 1) {
      values = [ this.state.monStart, this.state.monEnd ]
    } else if (day === 2) {
      values = [ this.state.tueStart, this.state.tueEnd ]
    } else if (day === 3) {
      values = [ this.state.wedStart, this.state.wedEnd ]
    } else if (day === 4) {
      values = [ this.state.thuStart, this.state.thuEnd ]
    } else if (day === 5) {
      values = [ this.state.friStart, this.state.friEnd ]
    } else if (day === 6) {
      values = [ this.state.satStart, this.state.satEnd ]
    }

    const start = (values[0] || '00:00').split(':');
    const hourStart = parseInt(start[0]);
    const minuteStart = parseInt(start[1]);

    const dateStart = new Date();
    dateStart.setHours(hourStart);
    dateStart.setMinutes(minuteStart);
    dateStart.setSeconds(0);
    dateStart.setMilliseconds(0);

    const end = (values[1] || '00:00').split(':');
    const hourEnd = parseInt(end[0]);
    const minuteEnd = parseInt(end[1]);

    const dateEnd = new Date();
    dateEnd.setHours(hourEnd);
    dateEnd.setMinutes(minuteEnd);
    dateEnd.setSeconds(0);
    dateEnd.setMilliseconds(0);

    return [ dateStart, dateEnd ];
  }

  private getDate = (day: number, isStart: boolean): Date => {
    const dates = this.getDay(day);
    return dates[isStart ? 0 : 1];
  }

  private remove = (day: number): void => {
    const workingHours = [...this.props.feature.properties.workingHours || []];
    workingHours.splice(day, 1, []);
    workingHours.forEach((times, index) => {
      if (times[0] === null && times[1] === null) {
        workingHours[index] = []
      }
    })
    const feature = new Feature(this.props.feature.json);
    feature.properties.workingHours = workingHours;
    this.props.onChange(feature);
  }

  private renderDay = (day: number, label: string) => {
    const dateStart = this.getDate(day, true);
    const dateEnd = this.getDate(day, false);

    return <FormGroup label={label} inline={true}>
      <Row flex={1} className="ai-c">
        <TimePicker value={dateStart} onChange={date => this.onChange(day, true, date)} />
        <div className='m-h-6'> - </div>
        <TimePicker value={dateEnd} onChange={date => this.onChange(day, false, date)} />
        <Button icon="cross" intent="danger" minimal onClick={() => this.remove(day)} />
      </Row>
    </FormGroup>
  }

  render() {
    const label = <MenuItem
      text="Working Hours"
      icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
    />

    return <Column flex={1} className="edit-block working_hours">
      <FormGroup label={label}/>
      <Collapse isOpen={this.state.isOpen}>
        { this.renderDay(1, 'Mon') }
        { this.renderDay(2, 'Tue') }
        { this.renderDay(3, 'Wed') }
        { this.renderDay(4, 'Thu') }
        { this.renderDay(5, 'Fri') }
        { this.renderDay(6, 'Sat') }
        { this.renderDay(0, 'Sun') }
      </Collapse>
    </Column>
  }
}