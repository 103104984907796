import Observable from '../observable'
import BaseSource from '../sources/base_source'
import BaseLayer from '../layers/base_layer'
import BackgroundLayer from '../layers/background_layer'
import RasterLayer from '../layers/raster_layer'
import FillLayer from '../layers/fill_layer'
import LineLayer from '../layers/line_layer'
import FillExtrusionLayer from '../layers/fill_extrusion_layer'
import SymbolLayer from '../layers/symbol_layer'
import HeatmapLayer from '../layers/heatmap_layer'
import CircleLayer from '../layers/circle_layer'
import HillshadeLayer from '../layers/hillshade_layer'

import { diff } from 'deep-diff'
import { BaseURL, debug, isDevPortal } from '../common'
import { METADATA_POLYGON_EDITING } from '../constants'
import DEFAULT_METADATA from '../metadata'

export default class Style extends Observable {
  id: string
  organization_id: string
  version: number
  name: string
  metadata: any
  center: [number, number]
  zoom: number
  bearing: number
  pitch: number
  sources: { [id: string]: any }
  glyphs: string
  layers: BaseLayer[]
  overlay: boolean = false
  segments: boolean = false
  routable: boolean = false
  cluster: boolean = true
  rooms: boolean = true
  fills: boolean = true

  constructor(data:any) {
    super()
    this.id = data.id
    this.organization_id = data.organization_id
    this.version = data.version || 1
    this.name = data.name || 'New Style'
    this.metadata = Object.assign(DEFAULT_METADATA, data.metadata || {})
    this.center = data.center || [18.5550, 48.4437]
    this.zoom = data.zoom || 8
    this.bearing = data.bearing || 0
    this.pitch = data.pitch || 0
    this.sources = data.sources || []

    if (this.hasCustomTiles()) {
      this.sources['simple-tiles-source'] = {
        type: 'raster',
        tiles: [
          `${BaseURL}/imageproxy?source=${this.metadata['proximiio:raster:tileurl']}`
        ],
        tileSize: this.metadata['proximiio:raster:tilesize'] || 256
      }
    }

    this.glyphs = data.glyphs || ''
    this.layers = (data.layers || []).map((layer: any) => {
      if (layer.type === 'background') {
        return new BackgroundLayer(layer)
      }
      if (layer.type === 'raster') {
        return new RasterLayer(layer)
      }
      if (layer.type === 'fill') {
        return new FillLayer(layer)
      }
      if (layer.type === 'line') {
        return new LineLayer(layer)
      }
      if (layer.type === 'fill-extrusion') {
        return new FillExtrusionLayer(layer)
      }
      if (layer.type === 'symbol') {
        return new SymbolLayer(layer)
      }
      if (layer.type === 'heatmap') {
        return new HeatmapLayer(layer)
      }
      if (layer.type === 'circle') {
        return new CircleLayer(layer)
      }
      if (layer.type === 'hillshade') {
        return new HillshadeLayer(layer)
      }
      return new BaseLayer(layer)
    }).concat(this.getUniversalLayers('main'))
      .concat(this.getSyntheticLayers())
  }

  getUniversalLayers(source: string) {
    const filter = [
      "all",
      [ "==", [ "geometry-type" ], "Polygon" ],
      [ "any",
        [ "==", [ "get", "segment" ], false ],
        [ "!",
          [ "has", "segment" ]
        ],
      ],
      [ "any",
        [ "==", [ "get", "routable" ], false ],
        [ "!",
          [ "has", "routable" ]
        ]
      ],
      [ "==", [ "get", "level" ], 0 ]
    ]

    const segmentFilter = [
      "all",
      [ "==", [ "geometry-type" ], "Polygon" ],
      [ "has", "segment" ],
      [ "==", [ "get", "segment" ], true ],
      [ "==", [ "get", "level" ], 0 ]
    ]

    const routableFilter = [
      "all",
      [ "==", [ "geometry-type" ], "Polygon" ],
      [ "has", "routable" ],
      [ "==", [ "get", "routable" ], true ],
      [ "==", [ "get", "level" ], 0 ]
    ]

    const roomFilter = [
      "all",
      [ "==", [ "geometry-type" ], "Polygon" ],
      [ "has", "room" ],
      [ "==", [ "get", "room" ], true ],
      [ "==", [ "get", "level" ], 0 ]
    ]

    const directionsFilter = [
      "all", 
      [ "==", [ "get", "class" ], "path" ],
      [ "==", [ "to-boolean", [ "has", "bidirectional" ] ], true ],
      [ "==", [ "to-boolean", [ "get", "bidirectional" ] ], false ],
      [ "==", [ "to-boolean", [ "get", "swapDirection" ] ], false ],
      [ "==", [ "get", "visibility" ], "visible" ],
      [ "==", [ "to-number", [ "get", "level" ] ], 0 ]
    ];

    const directionsOppositeFilter = [
      "all", 
      [ "==", [ "get", "class" ], "path" ],
      [ "==", [ "to-boolean", [ "has", "bidirectional" ] ], true ],
      [ "==", [ "to-boolean", [ "get", "bidirectional" ] ], false ],
      [ "==", [ "to-boolean", [ "get", "swapDirection" ] ], true ],
      [ "==", [ "get", "visibility" ], "visible" ],
      [ "==", [ "to-number", [ "get", "level" ] ], 0 ]
    ];

    const base = {
      minzoom: 1,
      maxzoom: 24,
      source
    }

    const layers = [
      new FillLayer({
        ...base,
        id: `${source}-polygon-fill`,
        type: 'fill',
        filter,
        layout: {
          "visibility": "none"
        },
        paint: {
          'fill-color': '#08c',
          'fill-opacity': 0.3
        }
      }),
      new LineLayer({
        ...base,
        id: `${source}-polygon-outline`,
        type: 'line',
        filter,
        layout: {
          "line-join": "bevel",
          "visibility": "none"
        },
        paint: {
          'line-color': '#08c',
          'line-width': 1,
          'line-opacity': 1
        }
      }),
      new LineLayer({
        ...base,
        id: `${source}-room-outline`,
        type: 'line',
        roomFilter,
        layout: {
          "line-join": "bevel",
          "visibility": "none"
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 1,
          'line-opacity': 1
        }
      }),
      new FillLayer({
        ...base,
        id: `${source}-room-fill`,
        type: 'fill',
        filter: roomFilter,
        layout: {
          "visibility": "none"
        },
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.3
        }
      }),
      // segments
      new FillLayer({
        ...base,
        id: `${source}-segment-fill`,
        type: 'fill',
        filter: segmentFilter,
        layout: {
          "visibility": "none"
        },
        paint: {
          'fill-color': '#0c8',
          'fill-opacity': 0.3
        }
      }),
      new LineLayer({
        ...base,
        id: `${source}-segment-outline`,
        type: 'line',
        filter: segmentFilter,
        layout: {
          "line-join": "bevel",
          "visibility": "none"
        },
        paint: {
          'line-color': '#0c8',
          'line-width': 1,
          'line-opacity': 1
        }
      }),
      // routables
      new FillLayer({
        ...base,
        id: `${source}-routable-fill`,
        type: 'fill',
        filter: routableFilter,
        layout: {
          "visibility": "none"
        },
        paint: {
          'fill-color': '#c80',
          'fill-opacity': 0.3
        }
      }),
      new LineLayer({
        ...base,
        id: `${source}-routable-outline`,
        type: 'line',
        filter: routableFilter,
        layout: {
          "line-join": "bevel",
          "visibility": "none"
        },
        paint: {
          'line-color': '#c80',
          'line-width': 1,
          'line-opacity': 1
        }
      })
    ] as BaseLayer[];

    if (isDevPortal) {
      layers.push(
        new SymbolLayer({
          ...base,
          id: `${source}-arrow-right`,
          type: 'symbol',
          minzoom: 9,
          maxzoom: 24,
          filter: directionsFilter,
          layout: {
            "icon-image": "arrow_right",
            "icon-size": 0.35,
            "symbol-placement": "line",
            "symbol-spacing": 40,
            "icon-rotation-alignment": "map"
          }
        }),
        new SymbolLayer({
          ...base,
          id: `${source}-arrow-left`,
          type: 'symbol',
          minzoom: 9,
          maxzoom: 24,
          filter: directionsOppositeFilter,
          layout: {
            "icon-image": "arrow_left",
            "icon-size": 0.35,
            "symbol-placement": "line",
            "symbol-spacing": 40,
            "icon-rotation-alignment": "map"
          }
        })
      )
    }

    return layers;
  }

  getSyntheticLayers() {
    const polygonFill = new FillLayer({
      type: 'fill',
      minzoom: 1,
      maxzoom: 24,
      source: 'synthetic',
      id: 'synthetic-polygon-fill',
      filter: [
        "all",
        [
          "==",
          [
            "geometry-type"
          ],
          "Polygon"
        ]
      ],
      layout: {
        "visibility": "visible"
      },
      paint: {
        'fill-color': '#08c',
        'fill-opacity': 0.3
      }
    })

    const polygonOutline = new LineLayer({
      type: 'line',
      minzoom: 1,
      maxzoom: 24,
      source: 'synthetic',
      id: 'synthetic-polygon-outline',
      filter: [
        "all",
        [
          "==",
          [
            "geometry-type"
          ],
          "Polygon"
        ]
      ],
      layout: {
        "line-join": "bevel",
        "visibility": "visible"
      },
      paint: {
        'line-color': '#08c',
        'line-width': 1,
        'line-opacity': 1
      }
    })

    return [
      polygonFill,
      polygonOutline,
    ]
  }

  usesPrefixes() {
    return typeof this.layers.find(layer => layer.id === 'proximiio-paths') !== 'undefined'
  }

  getLayer(id: string) {
    return this.layers.find(layer => layer.id === id)
  }

  getLayerIndex(id: string) {
    return this.layers.findIndex(layer => layer.id === id)
  }

  getLayers(source_id: string): BaseLayer[] {
    return this.layers.filter(layer => layer.source && layer.source === source_id)
  }

  getSources(): BaseSource[] {
    const sources = [] as BaseSource[]
    Object.keys(this.sources).forEach(id => {
      const source = this.sources[id]
      source.id = id
      sources.push(source)
    })
    return sources
  }

  hasCustomTiles = () => 
    typeof this.metadata['proximiio:raster:tileurl'] !== 'undefined' && 
    this.metadata['proximiio:raster:tileurl'].length > 0;

  setSource(id: string, data: BaseSource) {
    this.sources[id] = data.source
  }

  setLevel(level: number) {
    [...this.getLayers('main'), ...this.getLayers('route')].forEach(layer => {
      if (!layer.filter) {
        return
      }
      layer.setFilterLevel(level)
    })
    this.notify('filter-change')
  }

  get polygonEditing() {
    return this.metadata[METADATA_POLYGON_EDITING] || false
  }

  toggleCluster() {
    this.cluster = !this.cluster
    this.notify('cluster-toggled')
  }

  togglePolygonEditing() {
    if (this.metadata[METADATA_POLYGON_EDITING]) {
      this.metadata[METADATA_POLYGON_EDITING] = !this.metadata[METADATA_POLYGON_EDITING]
    } else {
      this.metadata[METADATA_POLYGON_EDITING] = true
    }

    this.notify('polygon-editing-toggled')
  }

  toggleRooms() {
    this.rooms = !this.rooms
    this.notify('roomsss-toggled')
  }

  toggleOverlay() {
    this.overlay = !this.overlay
    this.notify('overlay-toggled')
  }

  toggleSegments() {
    this.segments = !this.segments
    this.notify('segments-toggled')
  }

  toggleRoutable() {
    this.routable = !this.routable
    this.notify('routable-toggled')
  }

  togglePaths(enabled: boolean) {
    const layer = this.layers.find(layer => layer.id === 'proximiio-paths' || layer.id === 'paths')
    if (layer) {
      const updated = new LineLayer(Object.assign({}, layer.json))
      updated.layout.visibility = enabled ? 'visible' : 'none'
      this.updateLayer(updated)
    }
  }

  updateLayer(layer: BaseLayer) {
    // debug.log('style updateLayer in:', layer)
    const idx = this.getLayerIndex(layer.id)
    let changes = [] as any
    if (idx >= 0) {
      const prev = this.layers[idx]
      const change = diff(prev.json, layer.json)
      debug.log('change', change)
      if (change) {
        changes.push(change)
        this.layers.splice(idx, 1, layer)
        // debug.log('style should update layer', layer, 'changes', changes)
        this.notify('layer-update', { layer, changes: changes.length > 0 ? changes[0] : [] })
      }
    }
  }

  setMetadata(namespace: string, item: string, value: any) {
    this.metadata[`${namespace}:${item}`] = value
    this.notify('metadata-update')
  }

  get namespaces(): string[] {
    const keys = Object.keys(this.metadata)
    const pairs = keys.filter(key => key.indexOf(':') > 0)
    const unique = new Set(pairs.map(pair => pair.split(':')[0]))
    return Array.from(unique).sort((a, b) => a.localeCompare(b))
  }

  namespaceItems(namespace: string) {
    return Object.keys(this.metadata)
      .filter(key => key.indexOf(`${namespace}:`) === 0)
      .map(key => (key.split(':').slice(1)).join(':'))
  }

  toggleFills() {
    this.fills = !this.fills;
    this.notify('fills-toggled')
  }

  get json(): any {
    const style = Object.assign({}, this)
    delete style._observers
    delete style.overlay
    style.layers = this.layers.map(layer => layer.json)
    return JSON.parse(JSON.stringify(style))
  }
}
